import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/styles'
import Paper from '@material-ui/core/Paper'

const useStyles = makeStyles({
  wrapper: {
    margin: '20px auto',
    width: '95%',
    maxWidth: 600,
    padding: 20,
  }
})

const ArticleLayout = ({ children }) => {
  const classes = useStyles();

  return (
    <Paper className={classes.wrapper} elevation={20}>
      {children}
    </Paper>
  )
}

export default ArticleLayout
